<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Header -->
      <div class="d-flex justify-content-between">
        <h4>Your followers</h4>
        <b-button variant="primary" @click="inviteFriends">Invite friends</b-button>
      </div>
      <div class="mb-3">
        <small>
          <what-does-this-mean type="users-follow-you" />
        </small>
      </div>
      
      <!-- Table Top -->
      <div class="d-flex align-items-center justify-content-between mt-2">
        <!-- Per Page -->
        <div cols="6" md="6">
          <!-- page length -->
          <b-form-group
            label="Show"
            label-cols="5"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-input
              v-model="searchFollower"
              type="text"
              size="sm"
              placeholder="Search follower"
              required
          />
        </div>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="filteredList"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <!-- Column: User -->
      <template #cell(user)="data">
        <span v-if="data.item.following_u.roles[0].name=='User'" class="font-weight-bold d-block text-nowrap">
          {{ data.item.following_u.username }}
        </span>
        <span v-else class="font-weight-bold d-block text-nowrap">
          {{ data.item.following_u.name }}
        </span>
      </template>

      <!-- Column: Follow since -->
      <template #cell(follow_since)="data">
        <span
          class="text-capitalize"
        >
          {{ data.item.created_at | prepareDate }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(action)="data">
        <div v-if="data.item.following_u.roles[0].name=='User'">
          <b-badge
            pill
            variant="light-danger"
            class="cursor-pointer mr-2"
            @click="removeFollower(data)"
          >
            <feather-icon icon="TrashIcon" size="14" /> 
            Remove
          </b-badge>
          <b-badge
            pill
            variant="light-danger"
            class="cursor-pointer"
            @click="blockUser(data)"
          >
            <feather-icon icon="SlashIcon" size="14" /> 
            Block
          </b-badge>
        </div>
      </template>
      
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from '../../main.js';
import WhatDoesThisMean from "@/components/modals/WhatDoesThisMean.vue"

import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    ToastificationContent,
    WhatDoesThisMean
  },
  data() {
    return {
      data: [],
      searchFollower: "",
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      fields: [
        { key: "user", label: "User" },
        { key: "follow_since", label: "Follower since", class: "text-center text-nowrap" },
        { key: "action", label: "Actions" }
      ],
    };
  },
  computed: {
    filteredList() {
      let searchTerm = this.searchFollower;
      let countFollowers = 0

      return this.data.filter(follower => {
          if(follower.following_u.username == null) {
              follower.following_u.username = ""
          }
          
          if(follower.following_u.name == null) {
              follower.following_u.name = ""
          }

          if(follower.following_u.username.toLowerCase().includes(searchTerm.toLowerCase()) || follower.following_u.name.toLowerCase().includes(searchTerm.toLowerCase())) {
              // Update total rows
              countFollowers++
              this.totalRows = countFollowers
              
              return follower;
          }
      });
    }
  },
  methods: {
    removeFollower(data) {
      // Show modal
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to remove ' + data.item.following_u.username + " from your followers?", {
        title: 'Are your sure?',
        size: 'sm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      }).then(value => {
        // On 'yes' remove follower  
        if(value == true) {
          this.$http.delete("/api/auth/user/follow-requests/remove/" + data.item.id)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                  title: "User removed from your followers list!",
                  icon: "CheckSquareIcon",
                  variant: "success",
              },
            });
            // Get followers
            this.getFollowers();              
          }).catch((error) => {
            console.log(error)

            this.$toast({
              component: ToastificationContent,
              props: {
                  title: "There was an error removing the user from your followers list!",
                  icon: "AlertCircleIcon",
                  variant: "danger",
              },
            });
          });
        }
      })
    },
    blockUser(data) {
      // Show modal
      this.$bvModal
            .msgBoxConfirm('Are you sure you want to ban ' + data.item.following_u.username + "?", {
            title: 'Are your sure?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
        }).then(value => {
            // On 'yes' lets ban user
            if(value == true) {
              this.$http.get("/api/auth/user/follow-requests/ban/" + data.item.following_u.id)
              .then((response) => {
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "User blocked!",
                          icon: "CheckSquareIcon",
                          variant: "success",
                      },
                  });
                  // Get followers   
                  this.getFollowers();  
                  EventBus.$emit('userBanned');
              }).catch((error) => {
                  console.log(error)
                  
                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "There was an error blocking the user!",
                          icon: "AlertCircleIcon",
                          variant: "danger",
                      },
                  });
              });
           }
        })
    },
    getFollowers() {
      // Request to get all followers
      this.$http.get("/api/auth/user/followers")
      .then((res) => {
        if(res != undefined) {
          this.data = res.data.followers

          // Set the initial number of items
          this.totalRows = this.data.length
        }
      })
      .catch((err) => {
        console.log(err);

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      });
    },
    inviteFriends() {
      EventBus.$emit('inviteFriends')
    }
  },
  mounted() {
    // Request to get all followers
    this.getFollowers();
    
    var pom_f = this;
    EventBus.$on('followRequestAccepted', () => {
      pom_f.getFollowers();
    });
  },
  watch:{
    data() {
      this.totalRows = this.data.length
    },
  }
};
</script>