<template>
  <div>
        <users-follow-you></users-follow-you>
        <users-you-follow></users-you-follow>
        <blocked-users></blocked-users>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";

import FollowButtonDetails from "../../components/users/FollowButtonDetails.vue"
import UsersYouFollow from "../../layouts/components/UsersYouFollow";
import UsersFollowYou from "../../layouts/components/UsersFollowYou";
import BlockedUsers from "../../layouts/components/BlockedUsers";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    FollowButtonDetails,
    UsersYouFollow,
    UsersFollowYou,
    BlockedUsers
  },
  data() {
    return {
      data: [],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      fields: [
        { key: "user", label: "User" },
        { key: "follow_since", label: "Follow since", class: "text-center text-nowrap" },
        { key: "action", label: "Actions" }
      ],
    };
  },
  mounted() {
    // Request to get all the users you follow
    this.$http.get("/api/auth/user/followings")
      .then((res) => {
        if(res != undefined) {
          this.data = res.data.followings

          // Set the initial number of items
          this.totalRows = this.data.length
        }
      })
      .catch((err) => {
        console.log(err);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style scoped>
  .card-header {
    display: none;
  }
</style>