<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Header -->
      <div class="d-flex justify-content-between">
        <h4>Blocked profiles</h4>
      </div>
      <div class="mb-3">
        <small>
          <what-does-this-mean type="blocked-users" />
        </small>
      </div>

      <!-- Table Top -->
      <div class="d-flex align-items-center justify-content-between mt-2">
        <!-- Per Page -->
        <div cols="6" md="6">
          <!-- page length -->
          <b-form-group
            label="Show"
            label-cols="5"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </div>
        <div>
          <b-form-input
              v-model="searchUser"
              type="text"
              size="sm"
              placeholder="Search profiles"
              required
          />
        </div>
      </div>
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="filteredList"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <!-- Column: User -->
      <template #cell(user)="data">
        <span class="font-weight-bold d-block text-nowrap">
          {{ data.item.banned_user[0].username }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(action)="data">
          <b-badge
            pill
            variant="light-primary"
            class="cursor-pointer"
            @click="unbanUser(data)">
             Unban</b-badge>
      </template>
      
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import EventBus from '../../main.js';
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import WhatDoesThisMean from "@/components/modals/WhatDoesThisMean.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    WhatDoesThisMean,
    ToastificationContent
  },
  data() {
    return {
      data: [],
      searchUser: "",
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      fields: [
        { key: "user", label: "User" },
        { key: "action", label: "Actions" }
      ],
    };
  },
  computed: {
    filteredList() {
      let searchTerm = this.searchUser;
      let countUsers = 0

      return this.data.filter(user => {
          if(user.banned_user[0].username == null) {
              user.banned_user[0].username = ""
          }

          if(user.banned_user[0].username.toLowerCase().includes(searchTerm.toLowerCase())) {
              // Update total rows
              countUsers++
              this.totalRows = countUsers              
              return user;
          }
      });
    }
  },
  methods: {
    unbanUser(data) {
        // Show modal
        this.$bvModal
            .msgBoxConfirm('Are you sure you want to unban ' + data.item.banned_user[0].username + "?", {
            title: 'Are your sure?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
        }).then(value => {
            // On 'yes' lets unban user
            if(value == true) {
                this.$http.get("/api/auth/user/follow-requests/unban/" + data.item.banned_user[0].id)
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "The user was successfully unbaned!",
                            icon: "CheckSquareIcon",
                            variant: "success",
                        },
                    });
                    // Get fresh list of banned users
                    this.getBannedUsers();
                }).catch((error) => {
                  console.log(error)

                  this.$toast({
                      component: ToastificationContent,
                      props: {
                          title: "There was an error unbaning the user!",
                          icon: "AlertCircleIcon",
                          variant: "danger",
                      },
                  });
                });
            }
        })
    },

    // Get all banned users
    getBannedUsers() {
      this.$http.get("/api/auth/user/blocked-users")
      .then((res) => {
        if(res != undefined) {
          this.data = res.data.banned_users;

          // Set the initial number of items
          this.totalRows = this.data.length
        }
      })
      .catch((err) => {
        console.log(err);

        this.$toast({
        component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      });
    }
  },
  mounted() {
    // Request to get banned users
    this.getBannedUsers();

    var pom_b = this;
    EventBus.$on('userBanned', () => {
      pom_b.getBannedUsers();
    });
  },
  watch:{
    data() {
      this.totalRows = this.data.length
    },
  }
};
</script>

<style scoped>
  .card-header {
    display: none;
  }
</style>